import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/button';
import LoginLayout from '../log-in-layout';
import Svg from '../../components/svg';

const AgreementError = ({ errors, cancelPayment }) => {
  return (
    <LoginLayout
      headerClassName="-y-lg"
      hideNav
    >
      <div className="page_header -x-centered-desktop">
      </div>
      <div className="page_body">
        <div className="page_content">
          <div
            className="form -login-page-form"
          >
            <div className="layer -space-down-sm">
              <div className="page_splash splash">
                <Svg name="circled-cross" className="splash_icon" />
                <h3 className="js-page-title splash_title -small">Sorry, we can not enable recurring withdrawal for your wallet at the moment</h3>
              </div>
            </div>

            <div className="layer">
              <p className="font-size-secondary-responsive -clean-box">
                { errors.map((errText, idx) => (
                  <Fragment key={errText}>
                    {errText}
                    <br />
                    { idx !== errors.length - 1 ? (<br />) : null }
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <div className="page_control font-weight-bold">
          <Button
            transparency="full"
            href={process.env.REACT_APP_DOMAIN_NAME === 'GB_PAY' ? 'https://app.gunbrokerpay.com' : 'https://app.bitrail.io'}
            target="_blank"
          >
              Log In to {process.env.REACT_APP_DOMAIN_STRING} for further instructions

          </Button>
        </div>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={() => cancelPayment()}

        >
            Choose other payment method
        </Button>
      </div>
    </LoginLayout>
  );
};

AgreementError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  cancelPayment: PropTypes.func
};

export default AgreementError;
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BasicLayout from '../basic-layout';
import Button from '../../components/button';
import { capitalizeSentence } from '../../utils/string-utils';
import Svg from '../../components/svg';
import { getIconName } from '../../constants/card-account-constants';


const AgreementConfirmation = ({
  configurePayment,
  paymentSource,
  description,
  saveRecurrentPayment,
  backupCard,
}) => {
  return (
    <BasicLayout
      hideNav
      headerClassName="-payment -logo-centered"
      isBranding={true}
      poweredByType="full"
      wrapperClassName={
        cn('-no-page-header')
      }
    >
      <div className="page_header">
        <h1 className="page_title">Allow recurring withdrawal</h1>
      </div>

      <div className="page_body">
        <div className="page_content new-transaction">
          <div className="layer -space-down-sm">
            <div className="badge -as-form-message -alert full-width">
            The recipient will be able to charge the account selected at regular intervals
            </div>
          </div>

          <div className="description-list full-width -fixed-title -title-width-lg">
            <div className="description-list_body">
              <div className="description-list_group">
                <div className="description-list_item">
                  <div className="description-list_title">
                    Description
                  </div>
                  <div className="description-list_value">
                    {description}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="description-list full-width -fixed-title -title-width-lg">
            <div className="description-list_body">
              <div className="description-list_group">
                <div className="description-list_item">
                  <div className="description-list_title">
                    {capitalizeSentence(paymentSource.type)}
                  </div>
                  <div className="description-list_value font-weight-bold">
                    {paymentSource.name || paymentSource.description}&nbsp;&nbsp;&nbsp; 
                    {paymentSource.last4 && (
                      <Fragment>
                        <span className="account-number-bullets">
                          &bull;&bull;
                        </span>
                        {paymentSource.last4}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {backupCard && backupCard.card_account_id && paymentSource.sourceType === 'bank' && (
            <div className="new-transaction_description description-list -fixed-title -title-width-lg full-width order-backup__confirmation">
              <div className="description-list_header">
                <div className="description-list_header-item">Backup payment method</div>
              </div>
              <div className="description-list_body">
                <div className="description-list_group">
                  <div className="description-list_item">
                    <div className="description-list_title">
                        Credit card
                    </div>
                    <div className="description-list_value">
                      <span className="font-weight-bold" style={{ marginBottom: '2px'}}>
                        {backupCard.user_description || 'Credit Card'}
                      </span>
                      <div>
                        <Svg name={getIconName(backupCard.bank_name)} className="icon cards-page__icon" />
                        <span className="font-weight-bold font-size-secondary-responsive">
                          <span className="account-number-bullets">&bull;&bull;</span>{backupCard.account_number}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
      
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -cancel"
          onClick={() => configurePayment()}
        >
        Cancel
        </Button>
        <Button
          xSize="full"
          transparency="no"
          color="blue"
          className="page_control -primary"
          onClick={() => saveRecurrentPayment(paymentSource)}
        >
          Allow Withdrawal
        </Button>
      </div>

    </BasicLayout>);
};

AgreementConfirmation.propTypes = {
  configurePayment: PropTypes.func,
  paymentSource: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    last4: PropTypes.string,
    sourceType: PropTypes.string
  }),
  description: PropTypes.string,
  saveRecurrentPayment: PropTypes.func,
  backupCard: PropTypes.shape({
    user_description: PropTypes.string,
    bank_name: PropTypes.string,
    account_number: PropTypes.string,
    card_account_id: PropTypes.string
  }),
};

export default AgreementConfirmation;

export const CONSTANTS = {
  SET_FIELD: '@recurrent-payment/SET_FIELD',
  SET_FIELDS: '@recurrent-payment/SET_FIELDS',
  SAVE_RECURRENT_PAYMENT: '@recurrent-payment/SAVE_RECURRENT_PAYMENT',
  CANCEL_PAYMENT: '@recurrent-payment/CANCEL_PAYMENT',
  MAKE_PREFLIGHT: '@recurrent-payment/MAKE_PREFLIGHT',

  // STEPS CONSTANTS
  STEP_CONFIGURATION: '@recurrent-payment/STEP_CONFIGURATION',
  STEP_CONFIRMATION: '@recurrent-payment/STEP_CONFIRMATION',
};

export default CONSTANTS;
import { assoc, assocPath, keys, compose } from 'ramda';
import CONST from '../constants/login-constants';

export const INIT_STATE = {
  email: '',
  password: '',
  phoneNumber: '',
  lastPhoneDigits: '',
  phonePrompt: '',
  mnoLink: '',
  ssnDigits: '',
  userId: null,
  agreement: false,
  expiration: 0,
  isLoading: false,
  payfoneIsLoading: false,
  step: CONST.STEP_EMAIL_PASSWORD,
  errors: {},
  isTrustedDevice: false,
};

const loginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.CLEAR_STATE: 
      return INIT_STATE;
    case CONST.SET_ERROR:
      return assocPath(['errors', action.payload.field], action.payload.error, state);
    case CONST.CLEAN_ERRORS:
      return assoc('errors', INIT_STATE.errors, state);
    case CONST.SET_FIELD:
      if (keys(INIT_STATE.validationErrors).indexOf(action.payload.field) !== -1) {
        return compose(
          assocPath([action.payload.field], action.payload.value),
          assocPath(['errors', action.payload.field], null),
        )(state);
      }
      return assocPath([action.payload.field], action.payload.value, state);
    default:
      return state;
  }
};

export default loginReducer;

import React from 'react';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import Loader from '../../components/loader';
import { connect } from 'react-redux';
import { setField, cancelPayment, saveRecurrentPayment, makePreflight } from '../../actions/recurrent-payment-actions';
import { defaultAccountSelector } from '../../reducers/wallet-reducer';
import { canUseACHSelector } from '../../reducers/app-reducer';
import AgreementConfiguration from './agreement-configuration';
import AgreementError from './agreement-error';
import AgreementConfirmation from './agreement-confirmation';
import CONST from '../../constants/recurrent-payment-constants';
import { isCreditCardsDisabledSelector } from '../../reducers/app-reducer';

const RecurrentPayment = ({ 
  recurrentPayment,
  cardAccounts,
  setField,
  saveRecurrentPayment,
  wallet, cancelPayment,
  defaultAccount,
  isBankAccountsDisabled,
  permissions,
  makePreflight,
  preflightIsLoading,
  preflightAccounts = [[], []],
  isCreditCardsDisabled,
}) => {
  const { selectedBankAccount, selectedAccount, isLoading, paymentInProcess, step } = recurrentPayment;

  const {
    destWalletHandle,
    amount,
    currency,
    backup_card,
    description,
  } = recurrentPayment;

  const confirmPayment = () => {
    setField('step', CONST.STEP_CONFIRMATION);
  };

  const configurePayment = () => {
    setField('step', CONST.STEP_CONFIGURATION);
  };

  if (isLoading) {
    return (
      <div className="page -basic">
        <div className="page_wrapper -y-full -content-x-centered -content-y-centered">
          <div className="page_wrapper-inner">
            <div className="page_body">
              <div className="page_content">
                <Loader color="blue" size="sm" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let errors = [];

  if (!wallet.identity_verified || wallet.status !== 'active') {
    errors.push('Your account should be verified and active');
  }

  if (preflightAccounts[0].length === 0 && !preflightIsLoading) {
    errors.push('You need at least one confirmed bank account, that has withdrawal allowed');
  }

  const paymentIsAllowed = !errors.length || preflightIsLoading;

  if (paymentIsAllowed) {
    if (step === CONST.STEP_CONFIGURATION) {
      return (
        <AgreementConfiguration
          amount={amount}
          cardAccounts={cardAccounts}
          cancelPayment={cancelPayment}
          currency={currency}
          defaultAccount={defaultAccount}
          description={description}
          destWalletHandle={destWalletHandle}
          isBankAccountsDisabled={isBankAccountsDisabled}
          makePreflight={makePreflight}
          paymentInProcess={paymentInProcess}
          permissions={permissions}
          preflightAccounts={preflightAccounts}
          preflightIsLoading={preflightIsLoading}
          confirmPayment={confirmPayment}
          selectedAccount={selectedAccount}
          selectedBankAccount={selectedBankAccount}
          setField={setField}
          isCreditCardsDisabled={isCreditCardsDisabled}
        />
      );
    }
    if (step === CONST.STEP_CONFIRMATION) {
      return (<AgreementConfirmation
        configurePayment={configurePayment}
        paymentSource={selectedAccount || selectedBankAccount}
        saveRecurrentPayment={saveRecurrentPayment}
        description={description}
        backupCard={backup_card}
      />);
    }
  }

  return (<AgreementError errors={errors} cancelPayment={cancelPayment} />);
};

RecurrentPayment.propTypes = {
  cardAccounts: PropTypes.arrayOf(PropTypes.shape(types.walletReducerTypes.cardAccounts)),
  recurrentPayment: PropTypes.shape(types.recurrentPaymentReducerTypes),
  setField: PropTypes.func,
  saveRecurrentPayment: PropTypes.func,
  makePreflight: PropTypes.func,
  wallet: types.walletReducerTypes.wallet,
  cancelPayment: PropTypes.func,
  defaultAccount: types.AccountShape,
  isBankAccountsDisabled: PropTypes.bool,
  permissions: types.EnabledStatesShape,
  preflightIsLoading: PropTypes.bool,
  preflightAccounts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  isCreditCardsDisabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  cardAccounts: state.wallet.cardAccounts,
  wallet: state.wallet.wallet,
  recurrentPayment: state.recurrentPayment,
  permissions: state.user.user.enabled_states,
  defaultAccount: defaultAccountSelector(state),
  isBankAccountsDisabled: canUseACHSelector(state),
  preflightIsLoading: state.recurrentPayment.preflightIsLoading,
  preflightAccounts: state.recurrentPayment.preflightAccounts,
  isMorePaymentMethodsOpen: state.recurrentPayment.isMorePaymentMethodsOpen,
  isCreditCardsDisabled: isCreditCardsDisabledSelector(state),
});

export default connect(mapStateToProps, { setField, saveRecurrentPayment, cancelPayment, makePreflight })(RecurrentPayment);
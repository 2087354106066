export const CONSTANTS = {
  SET_FIELD: '@login/SET_FIELD',
  END_STEP: '@login/END_STEP',
  SET_ERROR: '@login/SET_ERROR',
  CLEAN_ERRORS: '@login/CLEAN_ERRORS',
  CLEAR_STATE: '@login/CLEAR_STATE',
  OPEN_POPUP: '@login/OPEN_POPUP',
  OAUTH_PENDING: '@login/OAUTH_PENDING',
  SEND_OTP: '@login/SEND_OTP',
  RESEND_OTP: '@login/RESEND_OTP',
  GET_OAUTH_TOKEN: '@login/GET_OAUTH_TOKEN',
  REFRESH_OAUTH_TOKEN: '@login/REFRESH_OAUTH_TOKEN',
  OAUTH_SEND_CONSENT: '@login/OAUTH_SEND_CONSENT',
  GET_CONSENT: '@login/GET_CONSENT',
  GET_AVI_STATUS: '@login/GET_AVI_STATUS',
  GENERATE_NEW_LINK: '@login/GENERATE_NEW_LINK',

  // STEPS CONSTANTS
  STEP_EMAIL_PASSWORD: '@login/STEP_EMAIL_PASSWORD',
  STEP_PAYPHONE_AUTODETECT: '@login/STEP_PAYPHONE_AUTODETECT',
  STEP_SENT_LINK: '@login/STEP_SENT_LINK',
  STEP_UNABLE_TO_VERIFY: '@login/STEP_UNABLE_TO_VERIFY',
  STEP_UNABLE_TO_LOGIN: '@login/STEP_UNABLE_TO_LOGIN',
  STEP_PHONE_NUMBER: '@login/STEP_PHONE_NUMBER',
  STEP_ERROR_TRY_AGAIN: '@login/STEP_ERROR_TRY_AGAIN',
  STEP_ERROR_HELPDESK: '@login/STEP_ERROR_HELPDESK',
  STEP_CONSENT: '@login/STEP_CONSENT',
  STEP_ADDITIONAL_VERIFICATION: '@login/STEP_ADDITIONAL_VERIFICATION',
  STEP_OTP: '@login/STEP_OTP',

  OTP_RESEND_TIMER: 110,

  ERROR_EXPIRED_ATTEMPT: 'expired attempt'
};


export const PHONE_PROMPT_STATUSES = {
  ASK: 'ask',
  FORCE: 'force',
  NO: 'no',
};


export default CONSTANTS;

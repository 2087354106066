import CONST from '../constants/login-constants';

export const clearState = () => ({
  type: CONST.CLEAR_STATE,
});

export const openPopup = () => ({
  type: CONST.OPEN_POPUP,
});

export const setError = (field, error) => ({
  type: CONST.SET_ERROR,
  payload: { field, error },
});


export const cleanErrors = () => ({
  type: CONST.CLEAN_ERRORS,
});

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const endStep = step => ({
  type: CONST.END_STEP,
  payload: { step },
});

export const oauthPending = () => ({
  type: CONST.OAUTH_PENDING
});

export const sendOtp = (isTrustedDevice) => ({
  type: CONST.SEND_OTP,
  payload: { isTrustedDevice }
});

export const resendOtp = () => ({
  type: CONST.RESEND_OTP
});

export const getOAuthToken = () => ({
  type: CONST.GET_OAUTH_TOKEN
});

export const refreshOAuthToken = () => ({
  type: CONST.REFRESH_OAUTH_TOKEN
});

export const oauthSendConsent = () => ({
  type: CONST.OAUTH_SEND_CONSENT,
});

export const getConsent = (consent) => ({
  type: CONST.GET_CONSENT,
  consent
});

export const getAviStatus = (avi) => ({
  type: CONST.GET_AVI_STATUS,
  avi
});

export const generateNewLink = () => ({
  type: CONST.GENERATE_NEW_LINK
});
import { assocPath, flatten, merge } from 'ramda';
import CONST from '../constants/recurrent-payment-constants';

const INIT_STATE = {
  isLoading: true,
  paymentInProcess: false,
  step: CONST.STEP_CONFIGURATION,
  agreementToken: '',
  destWalletHandle: '',
  frequency: '',
  amount: '',
  currency: '',
  description: '',
  eventId: '',
  rate: { 
    FC: {
      USD: '1',
    },
    USD: {
      FC: '1',
    },
  },
  selectedBankAccount: {},
  selectedAccount: { balance: {} },
  preflightIsLoading: true,
  preflightAccounts: [[], []],
};

const recurrentPaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath(flatten([action.payload.field]), action.payload.value, state);
    case CONST.SET_FIELDS:
      return merge(state, action.payload);
    default:
      return state;
  }
};

export default recurrentPaymentReducer;
